
import { Component, Vue, Prop } from 'vue-property-decorator'
import HsIconFont from '@/components/hs-iconfont'
import HeaderBarActionPanel from './HeaderBarActionPanel.vue'
import { NaviStateModule } from '@/store/navi_state'
import { EVENT_GLOBAL_GOTO_PAGE } from '@/event/event_types'
import HeaderLogo from './header-logo/HeaderLogo.vue'

@Component({
  name: 'HeaderBar',
  components: {
    HsIconFont,
    HeaderBarActionPanel,
    HeaderLogo
  }
})
export default class HeaderBar extends Vue {
  @Prop({ type: Boolean, default: false }) menuCollapsedStateIndex!: boolean

  get showCollapsedBtn(): boolean {
    return NaviStateModule.showCollapsedBtn
  }

  /**
   * 折叠菜单
   */
  collapseMenu() {
    console.log('header bar collapseMenu')
    NaviStateModule.toggleMenuCollapseState()
  }

  get menuCollapseState() {
    return NaviStateModule.menuCollapsed
  }

  public get mainMenuList(): Array<NaviNode> {
    return NaviStateModule.navi
  }

  /**
   * 如果当前导航不存在下级资源，直接跳转，否则查找第一个可用的子资源进行跳转
   * @param rootTarget - 目标根节点资源对象
   */
  public goto(rootTarget: NaviNode) {
    //去第一个叶子菜单项
    let curNode = rootTarget
    while (!!curNode.children && curNode.children.length > 0) {
      curNode = curNode.children[0]
    }
    console.log('header bar goto', rootTarget.name, curNode.name)
    this.$eventBus.$emit(EVENT_GLOBAL_GOTO_PAGE, {
      path: curNode.resourceUrl
    })
  }

  get activeRootMenuKey(): string | null {
    return NaviStateModule.activeMainMenuName
  }
}
