import { Icon } from 'ant-design-vue'
import { VueClass } from 'vue-class-component/lib/declarations'
import Vue from 'vue'

const HSIconFont = Icon.createFromIconfontCN({
  // scriptUrl: '//at.alicdn.com/t/c/font_3804258_d8rbyez77h.js'
  scriptUrl: '//at.alicdn.com/t/font_2735684_1cxdca363l9i.js'
})

export default <VueClass<Vue>>(<unknown>HSIconFont)
